import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import ArticlePage from "./abstract-article"
import { page } from "../articleTypes"

const PagePage = ({ data, ...props }) => {
  return <ArticlePage {...props} article={data.page} articleType={page} />
}
PagePage.propTypes = {
  data: PropTypes.any,
}

export const query = graphql`
  query($slug: String!) {
    page: markdownRemark(
      fields: { contentKind: { eq: "page" }, slug: { eq: $slug } }
    ) {
      ...TinaRemark
      ...ArticlePageFragment
    }
  }
`

export default PagePage
